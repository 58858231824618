@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../../utilities/globalmixins";

.category-tile-container {
    .main-heading {
        font-size: 3.5rem;
        font-weight: 700;
        line-height: 1;

        @include media-breakpoint-down(md) {
            font-size: 3rem;
        }
    }
}
